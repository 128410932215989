import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */ import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Breadcrumb from "../components/breadcrumb"
import Column from "../components/column"
import Columns from "../components/columns"
import Image from "../components/image"
import Layout from "./default"
import MDXProvider from "../components/mdx-provider"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"

const LayoutPodcast = ({ data: { mdx, ogImage } }) => {
  const { title, backdrop, cover } = mdx.frontmatter

  return (
    <Layout backdrop={backdrop || "turmgespraeche"}>
      <Seo
        title={title}
        description={mdx.excerpt}
        image={ogImage.frontmatter.cover.childImageSharp.gatsbyImageData}
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Turmgespräche",
              link: "/turmgespraeche",
            },
            {
              title: title,
              link: `/turmgespraeche${mdx.fields.slug}`,
            },
          ]}
        />
        <PageTitle>{title}</PageTitle>
        <Columns space={12} collapse={[true, true, false]}>
          <Column width={64}>
            <Image
              alt={`Titelbild zum Gespräch mit ${title}`}
              image={cover.childImageSharp.gatsbyImageData}
            />
          </Column>
          <Column>
            <Stack>
              <MDXProvider>
                <MDXRenderer>{mdx.body}</MDXRenderer>
              </MDXProvider>
            </Stack>
          </Column>
        </Columns>
      </Stack>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PodcastDetailQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt(pruneLength: 150)
      fields {
        slug
      }
      frontmatter {
        title
        backdrop
        cover {
          childImageSharp {
            gatsbyImageData(width: 200, layout: CONSTRAINED)
          }
        }
      }
    }
    ogImage: mdx(id: { eq: $id }) {
      frontmatter {
        cover {
          childImageSharp {
            gatsbyImageData(width: 800, height: 800, layout: FIXED)
          }
        }
      }
    }
  }
`

export default LayoutPodcast
